import { DateTime } from 'luxon'

function getRandomColors() {
    const classes = ['bg-blue-200', 'bg-red-200', 'bg-pink-200', 'bg-purple-200', 'bg-green-200', 'bg-yellow-200']
    let random = Math.floor(Math.random() * classes.length)
    return classes[random]
}

function isNumber(evt) {  
    const charCode = evt.which ? evt.which : evt.keyCode;  
    if (  
      charCode > 31 &&  
      (charCode < 48 || charCode > 57) &&  
      charCode !== 46  
    ) {  
      evt.preventDefault();  
    }  
  }

function validatePhoneNumber(input_str) {
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  
    return re.test(input_str);
}
function validateEmail(input_str) {
  var re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/; //eslint-disable-line
  return re.test(input_str);
}
function nameToShort(name){
    let firstLetters = name.split(' ')
    firstLetters = firstLetters.length  > 1 ?  firstLetters.map(wrd => wrd[0]).join('') : firstLetters[0]
    return firstLetters.substring(0,2).toUpperCase();   
}

function FormatDate(date_data){
  const date = DateTime.fromISO(date_data)
  const humanReadable = date.toLocaleString(DateTime.DATETIME_MED);
  return humanReadable
}
  
function FormatDateNew(date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}


export { getRandomColors, isNumber, validatePhoneNumber, validateEmail, nameToShort, FormatDate, onlyUnique, FormatDateNew }